import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const Resources = () => {
  const {
    contentfulPatientsPage: {
      careNeedsIntro: { raw: careNeedsIntroRaw },
      needs = [],
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          careNeedsIntro {
            raw
          }
          needs {
            description {
              raw
            }
            icon {
              description
              file {
                url
              }
            }
            id
          }
        }
      }
    `
  )

  const needsData = needs.map(
    ({
      id,
      icon: {
        description: iconDescription,
        file: { url: src },
      },
      description: { raw },
    }) => {
      return {
        id,
        iconDescription,
        src,
        description: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
      }
    }
  )

  const renderListItem = ({ id, iconDescription, src, description }) => (
    <div className={styles.resourcesFlex}>
      <img key={id} alt={iconDescription} src={src} />
      <div>{description}</div>
    </div>
  )

  return (
    <section className={styles.patientResources}>
      <div className={styles.textContainer}>
        <RichTextToReact
          document={JSON.parse(careNeedsIntroRaw)}
          options={renderOptions}
        />
        {needsData.map(renderListItem)}
      </div>
    </section>
  )
}

export default Resources
