import React from "react"
import * as styles from "./styles.module.less"
import { useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const HealthServices = () => {
  const {
    contentfulPatientsPage: {
      overview: { raw: overviewRaw },
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          overview {
            raw
          }
        }
      }
    `
  )
  return (
    <section className={styles.patientServices}>
      <div className={styles.container}>
        <RichTextToReact
          document={JSON.parse(overviewRaw)}
          options={renderOptions}
        />
      </div>
    </section>
  )
}

export default HealthServices
