import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import * as styles from "./styles.module.less"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const IntegratedCare = () => {
  const {
    contentfulPatientsPage: { integratedCare },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          integratedCare {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: {
            description,
            file: {
              url,
              details: { image },
            },
          },
        },
      }) => {
        return (
          <img
            className={styles.embeddedImg}
            src={url}
            alt={description}
            {...image}
          />
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri },
        } = node
        let attr = {}
        if (uri.match(/^https?:\/\//)) {
          attr = {
            target: "_blank",
            rel: "noreferrer",
          }
        }
        return (
          <a href={uri} {...attr}>
            {children}
          </a>
        )
      },
    },
  }

  return (
    <section className={styles.integratedCare}>
      <div className={styles.textContainer}>
        {renderRichText(integratedCare, renderOptions)}
      </div>
    </section>
  )
}

export default IntegratedCare
