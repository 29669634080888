import React from "react"

import HealthServices from "./components/HealthServices"
import Resources from "./components/Resources"
import IntegratedCare from "./components/IntegratedCare"
import Faq from "../../components/Faq"
import Visit from "./components/Visit"
import Hero from "../Hero"
import { Link } from "gatsby"
import * as styles from "./styles.module.less"
import LocationsMap, { LocationsMapHeader } from "../LocationsMap"
import { useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const PatientsPage = () => {
  const {
    contentfulPatientsPage: {
      hero: {
        headline,
        eyebrow,
        heroImage: {
          file: { url: heroImageUrl },
          description: heroImageDescription,
        },
      },
      locations: { raw: locationsRaw },
      faqs = [],
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          hero {
            headline
            eyebrow
            heroImage {
              file {
                url
              }
              description
            }
          }
          locations {
            raw
          }
          faqs {
            question {
              question
            }
            answer {
              raw
            }
            id
          }
        }
      }
    `
  )

  const faqsResources = faqs.map(
    ({ id, question: { question }, answer: { raw } }) => {
      return {
        id,
        question,
        answer: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
        label: "Q:",
      }
    }
  )

  const faqsData = {
    title: "Frequently asked questions",
    body: "",
    list: faqsResources,
  }

  return (
    <div>
      <Hero
        eybrow={eyebrow}
        headline={headline}
        image={heroImageUrl}
        alt={heroImageDescription}
      />
      <HealthServices />
      <section className={styles.locations}>
        <LocationsMapHeader />
        <LocationsMap />
        <div className={styles.copy}>
          <div className={styles.links}>
            <Link to="/Portland">Portland</Link>
            <Link to="/Beaverton">Beaverton</Link>
            <Link to="/Vancouver">Vancouver</Link>
            <Link to="/Salem">Salem</Link>
          </div>
          <div>
            <RichTextToReact
              document={JSON.parse(locationsRaw)}
              options={renderOptions}
            />
          </div>
          <Link to="/services" className={styles.cta}>
            See&nbsp;our&nbsp;services
          </Link>
        </div>
      </section>
      <Resources />
      <IntegratedCare />
      <Faq faqData={faqsData} />
      <Visit />
    </div>
  )
}

export default PatientsPage
