import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import PatientsPage from "../components/PatientsPage"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const {
    contentfulPatientsPage: {
      title,
      seo: { title: seoTitle, description: seoDescription, language },
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          seo {
            title
            description
            language
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo
        title={title || seoTitle}
        description={seoDescription}
        lang={language}
      />
      <PatientsPage />
    </Layout>
  )
}

export default IndexPage
