import React from "react"
import * as styles from "./styles.module.less"
import { useStaticQuery, graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const Visit = () => {
  const {
    contentfulPatientsPage: { preFooter = [] },
  } = useStaticQuery(
    graphql`
      {
        contentfulPatientsPage(slug: { eq: "patients" }) {
          preFooter {
            id
            resourceCopy {
              raw
            }
            resourceTitle
          }
        }
      }
    `
  )

  const preFooterData = preFooter.map(
    ({ id, resourceCopy: { raw }, resourceTitle }) => {
      return {
        id,
        body: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
        title: resourceTitle,
      }
    }
  )

  const renderPreFooter = ({ id, body, title }) => (
    <div key={id} className={styles.flexItems}>
      <div className={styles.caption}>{title}</div>
      <div className={styles.body}>{body}</div>
    </div>
  )

  return (
    <section className={styles.visit}>
      <div className={styles.flexContainer}>
        {preFooterData.map(renderPreFooter)}
      </div>
    </section>
  )
}

export default Visit
